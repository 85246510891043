// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoList_inputList__QtPE5 {
  display: flex;
  height: 48px;
  padding: 10px 20px;
  min-width: 600px;
}
.infoList_inputList_label__GVt8L {
  width: 140px;
  align-self: center;
}
.infoList_inputList_label__GVt8L .infoList_required__58tWb {
  color: rgb(218, 27, 27);
}
.infoList_inputList_input__KSAaO {
  width: 333px;
  position: relative;
  height: 48px;
}
.infoList_inputList_input__KSAaO input {
  width: 100%;
  height: 46px;
  padding: 0px 11px 1px 15px;
  border-radius: 4px;
  border: 1px solid rgb(221, 221, 221);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: rgb(51, 51, 51);
  outline: none;
  box-sizing: border-box;
}
.infoList_inputList_input__KSAaO .infoList_inputError__B82eI {
  border: 1px solid red;
}
.infoList_inputList_input__KSAaO .infoList_errorMsg__Vesqp {
  width: fit-content;
  color: red;
  font-size: 12px;
}
.infoList_inputList_button__ssBRO {
  width: 120px;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Signup/infoList.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AAAI;EACE,YAAA;EACA,kBAAA;AAEN;AADM;EACE,uBAAA;AAGR;AAAI;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AAEN;AADM;EACE,WAAA;EACA,YAAA;EACA,0BAAA;EACA,kBAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;AAGR;AADM;EACE,qBAAA;AAGR;AADM;EACE,kBAAA;EACA,UAAA;EACA,eAAA;AAGR;AAAI;EACE,YAAA;EACA,gBAAA;AAEN","sourcesContent":[".inputList {\n    display: flex;\n    height: 48px;\n    padding: 10px 20px;\n    min-width: 600px;\n    &_label {\n      width: 140px;\n      align-self: center;\n      .required {\n        color: rgb(218, 27, 27);\n      }\n    }\n    &_input {\n      width: 333px;\n      position: relative;\n      height: 48px;\n      input {\n        width: 100%;\n        height: 46px;\n        padding: 0px 11px 1px 15px;\n        border-radius: 4px;\n        border: 1px solid rgb(221, 221, 221);\n        font-weight: 400;\n        font-size: 16px;\n        line-height: 1.5;\n        color: rgb(51, 51, 51);\n        outline: none;\n        box-sizing: border-box;\n      }\n      .inputError {\n        border: 1px solid red;\n      }\n      .errorMsg {\n        width: fit-content;\n        color: red;\n        font-size: 12px;\n      }\n    }\n    &_button {\n      width: 120px;\n      margin-left: 8px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputList": `infoList_inputList__QtPE5`,
	"inputList_label": `infoList_inputList_label__GVt8L`,
	"required": `infoList_required__58tWb`,
	"inputList_input": `infoList_inputList_input__KSAaO`,
	"inputError": `infoList_inputError__B82eI`,
	"errorMsg": `infoList_errorMsg__Vesqp`,
	"inputList_button": `infoList_inputList_button__ssBRO`
};
export default ___CSS_LOADER_EXPORT___;
