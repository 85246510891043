// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BannerSub_banner_m__eUb0v {
  margin: auto;
  width: 74vw;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
}
.BannerSub_banner_m__eUb0v .BannerSub_title__QhyZV {
  padding-top: 65px;
  font-weight: 700;
  font-size: 14px;
  color: #404040;
  font-family: "Pretendard-Regular";
}
.BannerSub_banner_m__eUb0v .BannerSub_swiper__uQbgo .BannerSub_slide__25Blh {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 25px;
}
.BannerSub_banner_m__eUb0v .BannerSub_swiper__uQbgo .BannerSub_slide__25Blh img {
  display: block;
  width: 100%;
  border-radius: 30px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/BannerSub/BannerSub.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,oBAAA;AACJ;AAAI;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iCAAA;AAEN;AAEM;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;AAAR;AACQ;EACE,cAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AACV","sourcesContent":[".banner_m {\n    margin: auto;\n    width: 74vw;\n    height: auto;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    padding-bottom:50px;\n    .title {\n      padding-top: 65px;\n      font-weight: 700;\n      font-size: 14px;\n      color: #404040;\n      font-family: 'Pretendard-Regular'\n\n    }\n    .swiper {\n      .slide {\n        display: flex;\n        justify-content: center;\n        box-sizing:border-box;\n        margin-top: 25px;\n        img {\n          display: block;\n          width: 100%;\n          border-radius: 30px;\n          margin-right: 20px;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner_m": `BannerSub_banner_m__eUb0v`,
	"title": `BannerSub_title__QhyZV`,
	"swiper": `BannerSub_swiper__uQbgo`,
	"slide": `BannerSub_slide__25Blh`
};
export default ___CSS_LOADER_EXPORT___;
