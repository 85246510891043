// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CartPage_cart__r\\+6PW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.CartPage_cart__r\\+6PW .CartPage_container__cK2mN {
  width: 80%;
}
.CartPage_cart__r\\+6PW .CartPage_header__YFuRF {
  font-family: "Pretendard-Regular";
  border-bottom: 2px solid rgb(51, 51, 51);
  padding-bottom: 1rem;
  z-index: 200;
}
.CartPage_cart__r\\+6PW .CartPage_header__YFuRF h1 {
  font-size: 1.4rem;
  font-weight: 800;
  color: rgb(51, 51, 51);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Cart/CartPage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,qBAAA;EACA,mBAAA;AACJ;AAAA;EACI,UAAA;AAEJ;AAAA;EACI,iCAAA;EACA,wCAAA;EACA,oBAAA;EACA,YAAA;AAEJ;AAAI;EACE,iBAAA;EACA,gBAAA;EACA,sBAAA;AAEN","sourcesContent":[".cart {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    justify-items: center;\n    align-items: center;\n.container {\n    width: 80%;\n}\n.header {\n    font-family: 'Pretendard-Regular';\n    border-bottom: 2px solid rgb(51, 51, 51);\n    padding-bottom: 1rem;\n    z-index: 200;\n    \n    h1 {\n      font-size: 1.4rem;\n      font-weight: 800;\n      color: rgb(51, 51, 51);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart": `CartPage_cart__r+6PW`,
	"container": `CartPage_container__cK2mN`,
	"header": `CartPage_header__YFuRF`
};
export default ___CSS_LOADER_EXPORT___;
