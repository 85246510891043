// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__r-mZf {
  font-family: "Pretendard-Regular";
  width: 100%;
  height: 46px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #B8A683;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.Button_button__r-mZf:hover {
  background-color: #dcccad;
}
.Button_button__r-mZf:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.Button_displayNone__v6O5k {
  display: none;
}

.Button_white__JrlWn {
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
}

.Button_brown__JVLcJ {
  background-color: #B8A683;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,sBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,sBAAA;EACA,WAAA;EACA,sBAAA;AADF;;AAIA;EACE,yBAAA;EACA,WAAA;AADF","sourcesContent":[".button {\n  font-family: 'Pretendard-Regular';\n  width: 100%;\n  height: 46px;\n  font-size: 16px;\n  font-weight: bold;\n  color: #fff;\n  background-color: #B8A683; \n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n\n  &:hover {\n    background-color: #dcccad;\n  }\n\n  &:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n}\n\n.displayNone {\n  display: none;\n}\n\n.white {\n  background-color: #fff;\n  color: #000;\n  border: 1px solid #ddd;\n}\n\n.brown {\n  background-color: #B8A683;\n  color: #fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__r-mZf`,
	"displayNone": `Button_displayNone__v6O5k`,
	"white": `Button_white__JrlWn`,
	"brown": `Button_brown__JVLcJ`
};
export default ___CSS_LOADER_EXPORT___;
