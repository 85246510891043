// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductHeader_header__gjW0b {
  width: 100%;
  padding-top: 3rem;
  font-family: "Pretendard-Regular";
}

.ProductHeader_small__-cTNJ h1 {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 800;
  color: #404040;
}

.ProductHeader_medium__6fWHV h1 {
  font-size: 1.6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/ProductHeader.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;EACA,iCAAA;AACJ;;AACE;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAEJ;;AAAE;EACE,iBAAA;AAGJ","sourcesContent":[".header {\n    width: 100%;\n    padding-top: 3rem;\n    font-family: 'Pretendard-Regular';\n  }\n  .small h1 {\n    text-align: center;\n    font-size: 1.4rem;\n    font-weight: 800;\n    color: #404040;\n  }\n  .medium h1 {\n    font-size: 1.6rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `ProductHeader_header__gjW0b`,
	"small": `ProductHeader_small__-cTNJ`,
	"medium": `ProductHeader_medium__6fWHV`
};
export default ___CSS_LOADER_EXPORT___;
