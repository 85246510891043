// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BannerMain_banner__9w4l4 {
  width: 100vw;
  height: 370px;
}
.BannerMain_banner__9w4l4 .BannerMain_slide__Rxc4\\+ .BannerMain_image__EiFj3 {
  width: 100%;
  min-width: 1050px;
  height: 370px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/BannerMain/BannerMain.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;AACJ;AACM;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,iBAAA;AACR","sourcesContent":[".banner {\n    width: 100vw;\n    height: 370px;\n    .slide {\n      .image {\n        width: 100%;\n        min-width: 1050px;\n        height: 370px;\n        object-fit: cover;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `BannerMain_banner__9w4l4`,
	"slide": `BannerMain_slide__Rxc4+`,
	"image": `BannerMain_image__EiFj3`
};
export default ___CSS_LOADER_EXPORT___;
