// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Signup_signup__\\+kSEz {
  font-family: "Pretendard-Regular";
  box-sizing: border-box;
  margin: 90px auto 90px;
  width: 640px;
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Signup_signup__\\+kSEz .Signup_header__F84Ez {
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid rgb(51, 51, 51);
  padding-bottom: 50px;
}
.Signup_signup__\\+kSEz .Signup_header__F84Ez h1 {
  font-size: 1.4rem;
  font-weight: 800;
  color: rgb(51, 51, 51);
}
.Signup_signup__\\+kSEz .Signup_profile__S7A2O {
  display: flex;
  padding: 10px 20px;
}
.Signup_signup__\\+kSEz .Signup_profile__S7A2O label {
  width: 140px;
}
.Signup_signup__\\+kSEz .Signup_profile_inputContainer__FCl2V input {
  margin-bottom: 10px;
}
.Signup_signup__\\+kSEz .Signup_profile_inputContainer_img__VKZ0k {
  border: 1px solid #cccccc;
  width: 150px;
  height: 150px;
}
.Signup_signup__\\+kSEz .Signup_btn__SQZBh {
  padding: 40px 200px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Signup/Signup.module.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,sBAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACI;EACE,kBAAA;EACA,mBAAA;EACA,wCAAA;EACA,oBAAA;AACN;AAAM;EACE,iBAAA;EACA,gBAAA;EACA,sBAAA;AAER;AAEI;EACE,aAAA;EACA,kBAAA;AAAN;AACM;EACE,YAAA;AACR;AAEQ;EACE,mBAAA;AAAV;AAEQ;EACE,yBAAA;EACA,YAAA;EACA,aAAA;AAAV;AAKI;EACE,mBAAA;AAHN","sourcesContent":[".signup {\n    font-family: 'Pretendard-Regular';\n    box-sizing: border-box;\n    margin: 90px auto 90px;\n    width: 640px;\n    min-height: 80vh;\n    height: auto;\n    display: flex;\n    flex-direction: column;\n  \n    .header {\n      text-align: center;\n      margin-bottom: 20px;\n      border-bottom: 2px solid rgb(51, 51, 51);\n      padding-bottom: 50px;\n      h1 {\n        font-size: 1.4rem;\n        font-weight: 800;\n        color: rgb(51, 51, 51);\n      }\n    }\n  \n    .profile {\n      display: flex;\n      padding: 10px 20px;\n      label {\n        width: 140px;\n      }\n      &_inputContainer {\n        input {\n          margin-bottom: 10px;\n        }\n        &_img {\n          border: 1px solid #cccccc;\n          width: 150px;\n          height: 150px;\n        }\n      }\n    }\n  \n    .btn {\n      padding: 40px 200px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signup": `Signup_signup__+kSEz`,
	"header": `Signup_header__F84Ez`,
	"profile": `Signup_profile__S7A2O`,
	"profile_inputContainer": `Signup_profile_inputContainer__FCl2V`,
	"profile_inputContainer_img": `Signup_profile_inputContainer_img__VKZ0k`,
	"btn": `Signup_btn__SQZBh`
};
export default ___CSS_LOADER_EXPORT___;
