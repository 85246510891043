// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainDisplay_mainProduct__9T812 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
}
.MainDisplay_mainProduct__9T812 .MainDisplay_container__e3Nn8 {
  width: 74vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MainDisplay_mainProduct__9T812 .MainDisplay_container__e3Nn8 .MainDisplay_header__II4js {
  width: 100%;
  text-align: center;
}
.MainDisplay_mainProduct__9T812 .MainDisplay_container__e3Nn8 .MainDisplay_row__WKQAe {
  width: 100%;
  display: flex;
  justify-content: center;
}
.MainDisplay_mainProduct__9T812 .MainDisplay_container__e3Nn8 .MainDisplay_row__WKQAe .MainDisplay_swiper__t4HvZ {
  width: 100%;
  padding: 20px 0;
}
.MainDisplay_mainProduct__9T812 .MainDisplay_container__e3Nn8 .MainDisplay_row__WKQAe .MainDisplay_swiper__t4HvZ .MainDisplay_swiper-slide__H0fGN {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainDisplay_productCard__-Yf3V {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Pretendard-Regular";
}
.MainDisplay_productCard__-Yf3V .MainDisplay_productContent__EAOGP {
  font-family: "Pretendard-Regular";
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MainDisplay_productCard__-Yf3V .MainDisplay_productContent__EAOGP .MainDisplay_productImage__SuL3A {
  width: 260px;
  height: 364px;
  object-fit: cover;
  margin-bottom: 10px;
}
.MainDisplay_productCard__-Yf3V .MainDisplay_productContent__EAOGP .MainDisplay_productInfo__Il9LA h3 {
  font-size: 1rem;
  margin-bottom: 5px;
}
.MainDisplay_productCard__-Yf3V .MainDisplay_productContent__EAOGP .MainDisplay_productInfo__Il9LA p {
  font-size: 0.9rem;
  color: #555;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/MainDisplay/MainDisplay.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,oBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,WAAA;EACA,kBAAA;AACN;AAEI;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AAAN;AACM;EACE,WAAA;EACA,eAAA;AACR;AAAQ;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAEV;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iCAAA;AAFF;AAGE;EACE,iCAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AADJ;AAGI;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;AADN;AAKM;EACE,eAAA;EACA,kBAAA;AAHR;AAMM;EACE,iBAAA;EACA,WAAA;AAJR","sourcesContent":[".mainProduct {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  padding-bottom: 4rem;\n\n  .container {\n    width: 74vw;\n    display: flex;\n    flex-direction: column; // 세로 정렬\n    align-items: center;\n\n    .header {\n      width: 100%;\n      text-align: center;\n    }\n\n    .row {\n      width: 100%;\n      display: flex;\n      justify-content: center;\n      .swiper {\n        width: 100%;\n        padding: 20px 0; // 상하 여백\n        .swiper-slide {\n          display: flex;\n          justify-content: center;\n          align-items: center;\n        }\n      }\n    }\n  }\n}\n\n.productCard {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  font-family: 'Pretendard-Regular';\n  .productContent {\n    font-family: 'Pretendard-Regular';\n    width: 90%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .productImage {\n      width: 260px;\n      height: 364px;\n      object-fit: cover;\n      margin-bottom: 10px;\n    }\n\n    .productInfo {\n      h3 {\n        font-size: 1rem;\n        margin-bottom: 5px;\n      }\n\n      p {\n        font-size: 0.9rem;\n        color: #555;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainProduct": `MainDisplay_mainProduct__9T812`,
	"container": `MainDisplay_container__e3Nn8`,
	"header": `MainDisplay_header__II4js`,
	"row": `MainDisplay_row__WKQAe`,
	"swiper": `MainDisplay_swiper__t4HvZ`,
	"swiper-slide": `MainDisplay_swiper-slide__H0fGN`,
	"productCard": `MainDisplay_productCard__-Yf3V`,
	"productContent": `MainDisplay_productContent__EAOGP`,
	"productImage": `MainDisplay_productImage__SuL3A`,
	"productInfo": `MainDisplay_productInfo__Il9LA`
};
export default ___CSS_LOADER_EXPORT___;
