// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductAdd_productAdd__F7Byx {
  font-family: "Pretendard-Regular";
  margin-bottom: 50px;
}
.ProductAdd_productAdd__F7Byx .ProductAdd_header__dFjf6 {
  font-family: "Pretendard-Regular";
  border-bottom: 2px solid rgb(51, 51, 51);
  padding-bottom: 1rem;
  z-index: 200;
  margin-bottom: 30px;
}
.ProductAdd_productAdd__F7Byx .ProductAdd_header__dFjf6 h1 {
  font-size: 1.4rem;
  font-weight: 800;
  color: rgb(51, 51, 51);
}
.ProductAdd_productAdd__F7Byx form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.ProductAdd_productAdd__F7Byx form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.ProductAdd_productAdd__F7Byx form input,
.ProductAdd_productAdd__F7Byx form textarea {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  font-family: "Pretendard-Regular";
  margin-top: 10px;
}
.ProductAdd_productAdd__F7Byx form textarea {
  resize: vertical;
}
.ProductAdd_productAdd__F7Byx form select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  font-family: "Pretendard-Regular";
  margin-top: 10px;
}
.ProductAdd_productAdd__F7Byx form .ProductAdd_btn__erYIE {
  gap: 1rem;
  margin: 0 100px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Mypage/ProductAdd/ProductAdd.module.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,mBAAA;AACJ;AAAI;EACI,iCAAA;EACA,wCAAA;EACA,oBAAA;EACA,YAAA;EACA,mBAAA;AAER;AADQ;EACE,iBAAA;EACA,gBAAA;EACA,sBAAA;AAGV;AACI;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACN;AACM;EACE,iBAAA;EACA,qBAAA;AACR;AAEM;;EAEE,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,iCAAA;EACA,gBAAA;AAAR;AAGM;EACE,gBAAA;AADR;AAIM;EACC,eAAA;EACC,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,iCAAA;EACA,gBAAA;AAFR;AAKM;EACE,SAAA;EACA,eAAA;EACA,gBAAA;AAHR","sourcesContent":[".productAdd {\n    font-family: 'Pretendard-Regular';\n    margin-bottom: 50px;\n    .header {\n        font-family: 'Pretendard-Regular';\n        border-bottom: 2px solid rgb(51, 51, 51);\n        padding-bottom: 1rem;\n        z-index: 200;\n        margin-bottom: 30px;\n        h1 {\n          font-size: 1.4rem;\n          font-weight: 800;\n          color: rgb(51, 51, 51);\n        }\n      }\n  \n    form {\n      display: flex;\n      flex-direction: column;\n      gap: 1.5rem;\n  \n      label {\n        font-weight: bold;\n        margin-bottom: 0.5rem;\n      }\n  \n      input,\n      textarea {\n        padding: 0.5rem;\n        border: 1px solid #ddd;\n        border-radius: 4px;\n        font-size: 1rem;\n        width: 100%;\n        box-sizing: border-box;\n        font-family: 'Pretendard-Regular';\n        margin-top: 10px;\n      }\n  \n      textarea {\n        resize: vertical;\n      }\n      \n      select {\n       padding: 0.5rem;\n        border: 1px solid #ddd;\n        border-radius: 4px;\n        font-size: 1rem;\n        width: 100%;\n        box-sizing: border-box;\n        font-family: 'Pretendard-Regular';\n        margin-top: 10px;\n      }\n\n      .btn {\n        gap: 1rem;\n        margin: 0 100px;\n        margin-top: 20px;\n      }\n    }\n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productAdd": `ProductAdd_productAdd__F7Byx`,
	"header": `ProductAdd_header__dFjf6`,
	"btn": `ProductAdd_btn__erYIE`
};
export default ___CSS_LOADER_EXPORT___;
