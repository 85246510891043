// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.MypageNavbar_mypage__z0DNl {
  width: 25%;
  display: flex;
  flex-direction: column;
  z-index: 200;
  margin-left: 15rem;
  font-family: "Pretendard-Regular";
}
.MypageNavbar_mypage__z0DNl h1 {
  font-size: 1.6rem;
  font-weight: 800;
  color: rgb(51, 51, 51);
  margin-left: 0; /* h1의 추가 여백 제거 */
}
.MypageNavbar_mypage__z0DNl .MypageNavbar_mypageNav__Gq10M {
  display: flex;
  flex-direction: column;
}
.MypageNavbar_mypage__z0DNl .MypageNavbar_mypageNav__Gq10M ul {
  list-style: none;
  padding: 0;
}
.MypageNavbar_mypage__z0DNl .MypageNavbar_mypageNav__Gq10M ul li {
  font-size: 0.9rem;
  border: 1px solid #eeeeee;
  border-bottom: none;
}
.MypageNavbar_mypage__z0DNl .MypageNavbar_mypageNav__Gq10M ul li a {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.MypageNavbar_mypage__z0DNl .MypageNavbar_mypageNav__Gq10M ul li a:hover {
  background-color: rgb(250, 250, 250);
  color: #a99773;
  font-weight: 600;
}
.MypageNavbar_mypage__z0DNl .MypageNavbar_mypageNav__Gq10M ul li:last-child {
  border-bottom: 1px solid #eeeeee;
}`, "",{"version":3,"sources":["webpack://./src/components/Mypage/MypageNavbar.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,UAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,iCAAA;AAEJ;AADI;EACE,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA,EAAA,iBAAA;AAGN;AADI;EACE,aAAA;EACA,sBAAA;AAGN;AADM;EACE,gBAAA;EACA,UAAA;AAGR;AAFQ;EACE,iBAAA;EACA,yBAAA;EACA,mBAAA;AAIV;AAHU;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;AAKZ;AAJY;EACE,oCAAA;EACA,cAAA;EACA,gBAAA;AAMd;AAHU;EACE,gCAAA;AAKZ","sourcesContent":[".mypage {\n    width: 25%;\n    display: flex;\n    flex-direction: column;\n    z-index: 200;\n    margin-left: 15rem;\n    font-family: 'Pretendard-Regular';\n    h1 {\n      font-size: 1.6rem;\n      font-weight: 800;\n      color: rgb(51, 51, 51);\n      margin-left: 0; /* h1의 추가 여백 제거 */\n    }\n    .mypageNav {\n      display: flex;\n      flex-direction: column;\n      \n      ul {\n        list-style: none;\n        padding: 0;\n        li {\n          font-size: 0.9rem;\n          border: 1px solid #eeeeee;\n          border-bottom: none;\n          a {\n            padding: 1rem;\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n            color: inherit;\n            text-decoration: none;\n            cursor: pointer;\n            &:hover {\n              background-color: rgb(250, 250, 250);\n              color: #a99773;\n              font-weight: 600;\n            }\n          }\n          &:last-child {\n            border-bottom: 1px solid #eeeeee;\n          }\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mypage": `MypageNavbar_mypage__z0DNl`,
	"mypageNav": `MypageNavbar_mypageNav__Gq10M`
};
export default ___CSS_LOADER_EXPORT___;
