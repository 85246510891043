// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login__li2fj {
  font-family: "Pretendard-Regular";
  width: 340px;
  margin: 90px auto 60px;
  margin-top: 90px;
  margin-bottom: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.Login_login__li2fj h1 {
  font-weight: 800;
  line-height: 20px;
}
.Login_login_container_inputForm__SlH5D {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.Login_login_container_inputForm__SlH5D .Login_input__iDpaY {
  font-family: "Pretendard-Regular";
  height: 54px;
  padding: 0px 11px 1px 15px;
  border-radius: 4px;
  border: 1px solid rgb(221, 221, 221);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: rgb(51, 51, 51);
  outline: none;
}
.Login_login_container_inputForm__SlH5D .Login_input__iDpaY:focus {
  border: 1px solid rgb(51, 51, 51);
  background: none;
}
.Login_login_container_buttonSection__bYih7 {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.Login_login_loginButton__jdeMa {
  font-family: "Pretendard-Regular";
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/Login.module.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAAI;EACE,gBAAA;EACA,iBAAA;AAEN;AACM;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACR;AAAQ;EACE,iCAAA;EACA,YAAA;EACA,0BAAA;EACA,kBAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;AAEV;AADU;EACE,iCAAA;EACA,gBAAA;AAGZ;AACM;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,SAAA;AACR;AAGI;EACI,iCAAA;AADR","sourcesContent":[".login {\n    font-family: 'Pretendard-Regular';\n    width: 340px;\n    margin: 90px auto 60px;\n    margin-top: 90px;\n    margin-bottom: 60px;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n    h1 {\n      font-weight: 800;\n      line-height: 20px;\n    }\n    &_container {\n      &_inputForm {\n        display: flex;\n        flex-direction: column;\n        gap: 12px;\n        .input {\n          font-family: 'Pretendard-Regular';\n          height: 54px;\n          padding: 0px 11px 1px 15px;\n          border-radius: 4px;\n          border: 1px solid rgb(221, 221, 221);\n          font-weight: 400;\n          font-size: 16px;\n          line-height: 1.5;\n          color: rgb(51, 51, 51);\n          outline: none;\n          &:focus {\n            border: 1px solid rgb(51, 51, 51);\n            background: none;\n          }\n        }\n      }\n      &_buttonSection {\n        margin-top: 30px;\n        display: flex;\n        flex-direction: column;\n        width: 100%;\n        gap: 10px;\n      }\n     \n    }\n    &_loginButton {\n        font-family: 'Pretendard-Regular';\n      }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `Login_login__li2fj`,
	"login_container_inputForm": `Login_login_container_inputForm__SlH5D`,
	"input": `Login_input__iDpaY`,
	"login_container_buttonSection": `Login_login_container_buttonSection__bYih7`,
	"login_loginButton": `Login_login_loginButton__jdeMa`
};
export default ___CSS_LOADER_EXPORT___;
