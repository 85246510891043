// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserPage_mypage__izQEH {
  display: flex;
  width: 90vw;
  margin-top: 2rem;
}

.UserPage_mypageOutlet__z5a38 {
  width: 80%;
  margin-left: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Mypage/UserPage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEE;EACE,UAAA;EACA,iBAAA;AACJ","sourcesContent":[".mypage {\n    display: flex;\n    width: 90vw;\n    margin-top: 2rem;\n  }\n  \n  .mypageOutlet {\n    width: 80%;\n    margin-left: 2rem;\n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mypage": `UserPage_mypage__izQEH`,
	"mypageOutlet": `UserPage_mypageOutlet__z5a38`
};
export default ___CSS_LOADER_EXPORT___;
